
// Variables

$primary: #16A085;
$font-light-color: #888;
$font-color: #222;

// 1em als default behalten (vs 0.625*16)
// und per Mixin umrechnen
$font-size: 1em !default;

@mixin rem-font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 16) + px;
  font-size: $sizeValue + rem;
}

$enable-flex: true;
$enable-rounded: false;
$enable-shadows: false;

$grid-columns:      12;
$grid-gutter-width-base: 30px;

@import "../../node_modules/bootstrap/scss/bootstrap";
@import './iconfont-embedded.scss';
@import "./rouge.scss";
@import "./fonts.scss";


body {
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    font-size: 100%;
    line-height: 1.25;
    margin: 0;
    background: #fefefe;
    color: $font-color;
    -webkit-font-smoothing: antialiased;
}

h1,h2 {
  font-family: "Roboto Slab",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-weight: 700;
}
h3,h4,h5 {
  font-family: "Roboto Slab", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-weight: 600;
}


h1 {
  @include rem-font-size(2.5);
}

h2 {
  @include rem-font-size(2);
}

/** content-start */

#content-start {
  max-width: 800px;
  margin: 50px auto;
  padding: 0 10px;
  @include rem-font-size(1.4);
  line-height: 28px;
}

@include media-breakpoint-down(sm) {
  #info span[class^=icon] { display: none }
}

@include media-breakpoint-down(md) {
  #info {
    margin-bottom: 50px;
    width: 100%;
    display: block;
    text-align: center;


    h2 {
      margin: 0;
      margin-bottom: 10px;
      @include rem-font-size(1);
      color: $font-light-color;
    }

    h2 br { display: none }

    ul { padding: 0}

    ul:after {
      clear: both;
    }

    li {
      width: 24%;
      text-align: center;
      list-style: none;
      @include rem-font-size(1.2);
      float: left;
    }

    li a:hover {
      font-weight: bold;
    }

    span.icon-right { display: none }

  }
}

@include media-breakpoint-up(lg) {
  #info {
    position: relative;
    float: left;
    margin-left: -240px;
    margin-top: 20px;
    left: 0;
    width: 200px;
    text-align: right;

    span.icon-left { display: none }
  }

  #info nav {
    padding-top: 10px;
    ul { padding: 0 }
  }

  li.spacer:before {
    content: " ";
    display: block;
    width: 50%;
    height: 1px;
    position: absolute;
    right: 0;
    background: $primary;
  }

  li.spacer {
    margin-top: 10px;
    padding-bottom: 10px;
  }

  #info nav li {
    list-style: none;
    @include rem-font-size(1.2);

    a {
      color: $font-color;
    }

    a:hover {
      color: $primary;
    }
  }

  #info h1 {
    font-weight: 400;
  }

  #info h2 {
    @include rem-font-size(1);
    font-family: "Source Sans Pro", "Helvetica", "Arial", "sans-serif";
    font-weight: 400;
    color: $primary;
  }


}

/** Sidebar */
#info h1 {
  @include rem-font-size(1.2);
  margin-top: 5px;
  a { color: $font-color; }
  small { color: $font-color; @include rem-font-size(0.95); }
}

.avatar {
  // background: $primary;
  // border-radius: 50%;
  display: inline-block;
  width: 150px;
  height: 150px;
  text-align: right;
}

blockquote {
  padding-left: 20px;
  border-left: 6px solid #EEE;
  margin-left: 10px;
}

a {
  text-decoration: none;
  color: $primary;

  &:hover {
    color: darken($primary, 10%);
    text-decoration: underline;
  }
}

/** Index */

article {
  padding: 10px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;

  h2 a {
    color: $font-color;
    text-decoration: none;
  }

  h2 a:hover {
    color: $primary;
    text-decoration: none;
  }
}

.article-sub {
  @include rem-font-size(1.0);
  text-align: right;
  color: #888;
}

article.post {

  a:hover {
    text-decoration: underline;
  }

  p { margin: 0 0 20px; }
  h1 { margin-top: 40px; }
  h2 { margin-top: 50px; }
  h3,h4 { margin-top: 35px; }
  hr { border: 1px solid #ddd }

  code {
    background: rgba(0,0,0,.05);
    color: #555;
  }

  .centered { text-align: center }
  img { max-width: 100%; }

  /** No top margin for highlight names */
  p.hl-title {
    margin-bottom: 5px;
  }
  p.hl-title + div.highlight {
    margin-top: 0;
  }

  kbd {
    font-weight: bold;
    color: #107360;
    background: transparent;
    margin: 0;
    padding: 0.2rem;
  }
}

/** Hidden on mobile */
.hidden-on-small {
  @include media-breakpoint-down(md) {
    display: none;

  }

}
.show-on-small {
  @include media-breakpoint-up(lg) {
    display: none;
  }

  ul { padding: 0}

  ul:after {
    clear: both;
  }

  li {
    width: 48%;
    text-align: center;
    list-style: none;
    @include rem-font-size(1.2);
    float: left;
  }
}

/** Header */
.post-header {
  width: 100%;
  height: 70vh;
  position: relative;
  overflow: hidden;
  display: table;

  color: black;
  background: black;

  @include media-breakpoint-down(md) {
    display: none;
  }

  .background {
    position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      opacity: 0.9;
  }

  .continue {
    @include rem-font-size(2);
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    color: white;
    z-index: 2;
  }

  .header-text {
    width: 100%;
    display:table-cell;
    vertical-align:middle;
    text-align: center;
    position: relative;
    z-index: 2;

    h1 {
      color: white;
      @include rem-font-size(4);
      text-shadow: 2px 2px 1px rgba(25, 25, 25, 0.6);
      width: 60%;
      margin: 0 auto;
    }
    small {
      font-family: "Source Sans Pro", "Helvetica", "Arial", "sans-serif";
      font-weight: 400;
      color: white;
      text-shadow: 2px 2px 1px rgba(25, 25, 25, 0.6);
    }
  }

  &.dark-bg {
    color: white;

    .header-text h1 {
      small { color: white }
    }

  }

  .attribution {
    position: absolute;
    background: #222;
    bottom: 0;
    left: 0;
    padding: 5px;
    z-index: 2;
    vertical-align: bottom;
    a { color: #F2F1EF }
    a:hover { color: $primary }
  }
}

/** Highlighting */
.highlight {
  border: solid 1px #eee;
  background: #fefefe;
  margin: 20px 0;
  overflow-x: auto;
  overflow-y: hidden;
  @include rem-font-size(0.95);
  line-height: 120%;

    pre {
      margin: 0;
      padding: 2px;
    }

  .gl {
    background: #fafafa;
    padding-right: 5px;
    border-right: 1px solid #ddd;
    color: #999;
    
    /* Stop line numbers being visually selected */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

/** Aufzählungen ohne dl */
ul.listing li {
  padding-bottom: 10px;
  list-style: none;
  strong { display: inline-block; padding-right: 10px; }
}

/** Archive */
.archive h3 {
  margin-bottom: 30px;
  font-weight: 400;
  @include rem-font-size(1.2);
  small {
    color: #999;
    font-weight: 300;
    @include rem-font-size(1);
  }
}

footer {
  font-size: 20px;
  line-height: 22px;
}

.sticky--container {
  
  height: 400px;
  overflow-y: scroll;

  table {
    width: 100%;
  }

  th {
    background: #f1f1f1;
    position: sticky;
    top: 0px;
  }
}

.stackblitz-embedded {
  width: 100%;
  height: 90vh;
}

kbd {
  margin: 0 0.2rem;
}
